import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil | Bienvenue à Cozy Cruiser Car Rentals
			</title>
			<meta name={"description"} content={"Découvrez le plaisir de voyager en toute simplicité et confort. Chez Cozy Cruiser, nous croyons qu'il faut vous offrir plus qu'une simple voiture - nous vous offrons une expérience fluide et sans tracas qui rend chaque voyage spécial."} />
			<meta property={"og:title"} content={"Accueil | Bienvenue à Cozy Cruiser Car Rentals"} />
			<meta property={"og:description"} content={"Découvrez le plaisir de voyager en toute simplicité et confort. Chez Cozy Cruiser, nous croyons qu'il faut vous offrir plus qu'une simple voiture - nous vous offrons une expérience fluide et sans tracas qui rend chaque voyage spécial."} />
			<meta property={"og:image"} content={"https://loirevalley.live/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://loirevalley.live/images/08dbb44b.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://loirevalley.live/images/08dbb44b.svg"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="0px 0 0px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="40%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="--primary">
						Car Rentals
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						Cozy Cruiser{"  "}
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
						Découvrez le plaisir de voyager en toute simplicité et confort. Chez Cozy Cruiser, nous croyons qu'il faut vous offrir plus qu'une simple voiture - nous vous offrons une expérience fluide et sans tracas qui rend chaque voyage spécial. Que vous soyez en voyage d'affaires ou en vacances, notre flotte de véhicules bien entretenus est à votre service.
					</Text>
					<Button
						background="--color-primary"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--lead"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--primary"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Contactez-nous
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="60%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://loirevalley.live/images/1.jpg"
						display="block"
						margin="0px 0px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" margin="20px 0 0 0">
				Faites l'expérience de la facilité avec la location de voitures Cozy Cruiser
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Embarquez pour vos voyages avec un compagnon fiable à vos côtés. Notre gamme de voitures, des modèles urbains compacts aux véhicules familiaux spacieux, est équipée pour répondre à tous vos besoins. Nous sommes fiers d'offrir la propreté, le confort et la commodité dans chaque trajet.
			</Text>
		</Section>
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://loirevalley.live/images/2.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					md-width="100%"
					object-position="0%"
					max-height="900p"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="50px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Pourquoi choisir Cozy Cruiser Car Rentals ?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="100px" padding="25px 25px 25px 25px" background="#ffffff">
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Service personnalisé : Chaque client est unique, et notre service l'est tout autant. Notre équipe s'attache à comprendre vos besoins et à vous recommander le véhicule idéal pour votre voyage.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Des prix transparents : Pas de frais cachés, pas de surprises. Nous croyons en une tarification claire et franche, garantissant une expérience de location digne de confiance.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Assistance routière : La tranquillité d'esprit est garantie grâce à notre assistance routière 24 heures sur 24 et 7 jours sur 7, prête à vous aider dans toute situation imprévue.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Des options écologiques : Pour les personnes soucieuses de l'environnement, nous proposons une sélection de véhicules hybrides et économes en carburant, contribuant ainsi à un voyage plus écologique.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});